.App-loading{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255,0.9);
    z-index: 1;
  }
  
  .App-loading .App-spinner{
    position: absolute;
    text-align: center;
    margin: 0 auto;
    top: calc((100% - 200px) / 2);
    left: calc((100% - 200px) / 2);
  }
  
  .App-loading .Spinner-logo{
    position: absolute;
    width: 200px;
    height: 200px;
  }
  
  .App-loading .Spinner-anim{
    position: absolute;
    width: 200px;
    height: 200px;
  }