*{
	box-sizing: border-box;
}

body{
	background: #fff;
	padding: 0px;
	margin: 0px;
	font-family: 'Lufga';
	font-size: 16px;
}

.App {
  position: relative;
  width: 100%;
  height: 100%;
}

.App-container {
  position: relative;
  background-color: #f5f5f5;
  display: flex;
  padding: 30px 70px 50px 50px;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 700px;
  margin: 0 auto;
  font-size: 16px;
  color: #4c187f;
}

.App-container h2{
  padding-bottom: 10px;
}

.App-container .App-lang {
  position: absolute;
  width: 60px;
  display: flex;
  right: 85px;
  gap: 5px;
  top: 20px;
}

.App-container .App-lang svg{
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.App-container .App-lang svg:hover{
  filter: brightness(130%);
}

.App-container form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.App-container .App-input{
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.App-container input {
  height: 37px;
  padding-left: 10px;
  margin: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 390px;
  font-family: 'Lufga';
	font-size: 16px;
  font-weight: 400;
}

.App-container input:focus
{
  outline: none;
  border-color: #4c187f;
}

.App-container .Consent-div{
  display: flex;
  width: 100%;
  padding: 10px 0px;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
}

.App-container .Consent-div p{
  width: 390px;
  margin: 0px 5px;
  font-family: 'Lufga';
  font-size: 13px;
  font-weight: 300;
  text-align: left;
}

.App-container .Consent-div input{
  text-align: right;
  width: 13px;
  margin: 0px 10px 0px 0px;
}

.App-container .Error-div{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  width: 100%;
  font-family: 'Lufga';
  font-size: 13px;
  font-weight: 300;
  text-align: left;
  color: red;
}

.App-container .Error-div p{
  width: 390px;
  margin: 5px;
}

.App-container label {
  display: inline-block;
  user-select: none;
  text-align: right;
  padding-right: 10px;
  width: 160px;
}

.App-container .Submit-div {
  display: flex;
  width: 560px;
  padding-top: 20px;
  align-items: right;
  justify-content: right;
}

.App-container button {
  width: 200px;
  padding: 13px 26px;
  background-color: #ec6c3a;
  color: #ebebeb;
  border: none;
  border-radius: 23px;
  cursor: pointer;
}

.App-container button:hover {
  background-color: #f25416;
}

.App-container .Country-select-container{
  display: inline-block;
  width: 390px;
  text-align: left;
  cursor: pointer;
}

.App-container .Country-select-container .Country-select__control .Country-select__value-container .Country-select__input-container{
  padding: 0;
  margin: 0;
}

.App-container .Select-box {
	position: relative;
  user-select: none;
	width: 390px;
  margin: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
}

.App-container .Select-box .Options-container {
	position: absolute;
  display: flex;
  flex-direction: column;
	top: 37px;

	width: 390px;
	background-color: white;
  border: 1px solid #ccc;
  border-width: 1px 0px 0px 0px;
  border-radius: 4px 4px 0px 0px;

  max-height: 0;
	opacity: 0;
	overflow: hidden;

	order: 1;
}

.App-container .Selected {
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  box-sizing: border-box;
	color: #4c187f;
	position: relative;
  height: 37px;
  padding-left: 10px;
	order: 0;
}

.App-container .Selected-arrow {
  position: absolute;
  height: 21px;
  right: 0px;
  transition: 0.3s transform ease-in-out;
}

.App-container .Select-box .Options-container.active {
	max-height: 240px;
	opacity: 1;
}

.App-container .Select-box .Options-container.active + .Selected .Selected-arrow{
	transform: rotateX(180deg);
	top: 8px;
}

.App-container .Select-box .Options-container.active + .Selected{
  outline: 1px solid #4c187f;
  border-radius: 4px;
}

.App-container .Select-box .Option,
.App-container .Selected {
  width: 100%;
	cursor: pointer;
}

.App-container .Select-box .Option:hover {
	color: #ec6c3a;
  background-color: #ebebeb;
}

.App-container .Select-box label {
  display: flex;
  align-items: center;
  padding-left: 10px;
  width: 100%;
  height: 37px;
  border: 1px solid #ccc;
  border-width: 0px 1px 1px 1px;
  text-align: left;
	cursor: pointer;
}

.App-container .Select-box .Option input {
	display: none;
}

@media (max-width: 1024px) {
  .App-container{
    padding: 40px 20px 20px 0px;
  }
  .App-container input, .App-container .Consent-div p, .App-container .Error-div p, .App-container .Country-select-container,
  .App-container .Select-box, .App-container .Select-box .Options-container
  {
    width: 200px;
  }
  .App-container .App-lang
  {
    right: 10px;
  }
  .App-container .Submit-div
  {
    width: 360px;
  }
}
@media (max-width: 767px) {
}