.App-popup{
    z-index: 1;
}

.App-popup, .overlay{
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
}

.overlay{
    background-color: rgba(0,0,0,0.7)
}

.popup-content{
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: white;
    color: #4c187f;
    padding: 14px 28px;
    border-radius: 3px;
    max-width: 600px;
    min-width: 300px;
}

.close-popup{
    position: absolute;
    top: 5px;
    right: 10px;
    cursor: pointer;
    font-size: 20px;
    border: none;
    background: none;
    color: #777777;
    transition: 0.3 color ease-in-out;
}

.close-popup:hover{
    color: #333333;
}